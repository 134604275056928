.imageColumn {
    float: left;
    width: 66.66%;
    height: 30em;
    position: relative;
}
.textColumn{
    float: left;
    width: 33.33%;
    height: 30em;
    background-color: #2D3C4A;
}

.header{
    margin: auto;
    color: #FFFFFF;
    text-align: center;
    margin-top: 5.75em;
}

.text{
    margin: auto;
    color: #FFFFFF;
    text-align: center;
    margin-top: 1em;
    width: 22.5em;
}

.button{
    position: relative;
    margin-top: 2em;
}

.row:after {
    display: table;
    clear: both;
}

.photoCredit{

    position:absolute;
    bottom: 0;
    right:0;
    color: #BEC4C9;

    font-family: 'Roboto';
    font-style: italic;
    font-weight: 400;
    font-size: 10px;
    line-height: 12px;
}