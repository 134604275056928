.textDiv{
    width: 100%;
    left: 0px;
    height: 18em;
    /*top: 47.563em;*/
    background-color: #F6F6F6;
    display: flex;
    flex-direction: column;
}

.textTitle{

    /* H1 */
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 700;
    font-size: 36px;
    line-height: 42px;
    text-align: center;

    /* TextLight */
    color: #2D3C4A;

    /* Inside auto layout */
    /*flex: none;*/
    /*order: 0;*/
    /*align-self: stretch;*/
    /*flex-grow: 0;*/
    margin: auto;
    margin-top: 1em;
}
.textSubTitle{

    font-family: 'Roboto';
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 19px;
    text-align: center;
    width: 40em;

    margin: auto;

    color: #2D3C4A;


}